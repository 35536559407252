import Error from '../components/Error'
import React from 'react'
import Pagewrapper from '../components/PageWrapper'

export default function () {
    return (
        <Pagewrapper>
            <Error />
        </Pagewrapper>
    )
}

