import { Button, Result } from 'antd'
import React from 'react'
import { navigate } from 'gatsby'

export default function ErrorPage() {
    const goBack = () => {
        navigate('/')
    }

    return (
        <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
            extra={
                <Button onClick={goBack} type="primary">
                    Back Home
                </Button>
            }
        />
    )
}
